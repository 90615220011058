<template>
  <campaign-layout
    :showBack="computed_debug"
    :showMenu="computed_debug"
    :pageTitle="$t('pageTitle.Awards')"
  >
    <swiper
      v-show="!showModal"
      :effect="'coverflow'"
      :grabCursor="true"
      :centeredSlides="true"
      :slidesPerView="'auto'"
      :coverflowEffect="{
        rotate: 20,
        stretch: 50,
        depth: 100,
        modifier: 2,
        slideShadows: true,
      }"
      class="mySwiper"
    >
      <swiper-slide
        part="swiper-slide"
        v-for="slide in awardsList"
        :key="slide.id"
        class="slide card-item"
      >
        <div class="ion-text-center slide-container">
          <div class="slide-img" @click="presentAlert(slide)">
            <ion-avatar>
              <img :src="slide.url_image" />
            </ion-avatar>
          </div>
          <div class="slide-title">
            <h5>{{ slide.title }}</h5>
          </div>
          <div v-if="!slide.riscattato" class="slide-btn">
            <ion-button @click="redeemAward(slide)" part="native">
              <ion-icon v-if="slide.vinto" :icon="lockOpen"></ion-icon>
              <ion-icon v-else :icon="lockClosed"></ion-icon>
              Riscatta
            </ion-button>
          </div>
          <div v-if="slide.riscattato">
            <ion-icon
              class="icon-premio-riscattato"
              :icon="checkmarkCircle"
            ></ion-icon>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <br />
    <common-campaign-menu
      v-show="!showModal"
      on-award="true"
    ></common-campaign-menu>

    <div v-if="showModal" :class="{ 'show-modal': showModal }" class="modal">
      <div class="modal-content">
        <div class="title-container">
          <h4>{{ modalData.title }}</h4>
        </div>
        <div class="image">
          <ion-avatar>
            <img :src="modalData.url_image" />
          </ion-avatar>
        </div>
        <div v-if="modalData.perc_completamento" class="progressbar-container">
          <common-progress-bar
            class="height-10"
            :value="modalData.perc_completamento"
          ></common-progress-bar>
        </div>
        <div class="description-container">
          <ion-row class="contenuto">
            <ion-col>
              <ion-row v-if="modalData.description">
                <ion-col>
                  {{ $root.translate(modalData.description) }}
                </ion-col>
              </ion-row>
              <ion-row v-if="modalData.nomeCampagna">
                <ion-col>
                  Award vinto nella campagna:<br />
                  <span>{{ $root.translate(modalData.nomeCampagna) }}</span>
                </ion-col>
              </ion-row>
              <ion-row v-if="modalData.vintoData">
                <ion-col>
                  Award vinto in data:<br />
                  <span>{{
                    modalData.vintoData.slice(8, 10) +
                    "-" +
                    modalData.vintoData.slice(5, 7) +
                    "-" +
                    modalData.vintoData.slice(0, 4)
                  }}</span>
                </ion-col>
              </ion-row>
              <ion-row v-if="modalData.riscattatoData">
                <ion-col>
                  Award riscattato in data: <br />
                  <span>{{
                    modalData.riscattatoData.slice(8, 10) +
                    "-" +
                    modalData.riscattatoData.slice(5, 7) +
                    "-" +
                    modalData.riscattatoData.slice(0, 4)
                  }}</span>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </div>
        <ion-row class="ion-justify-content-center ion-margint-top">
          <ion-col size="auto">
            <ion-button @click="showModal = false"> Close </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <!-- <div v-if="showModal" :class="{ 'show-modal': showModal }" class="modal">
      <div class="modal-content">
        <h4>{{ modalData.title }}</h4>
        <div class="image">
          <img :src="modalData.url_image" />
        </div>
        <ion-row class="contenuto">
          <ion-col>
            <ion-row v-if="modalData.nomeCampagna">
              <ion-col>
                Award vinto nella campagna:
                <span>{{ $root.translate(modalData.nomeCampagna) }}</span>
              </ion-col>
            </ion-row>
            <ion-row v-if="modalData.vintoData">
              <ion-col>
                Award vinto in data:
                <span>{{
                  modalData.vintoData.slice(8, 10) +
                  "-" +
                  modalData.vintoData.slice(5, 8) +
                  "-" +
                  modalData.vintoData.slice(0, 5)
                }}</span>
              </ion-col>
            </ion-row>
            <ion-row v-if="modalData.riscattatoData">
              <ion-col>
                Award riscattato in data:
                <span>{{
                  modalData.riscattatoData.slice(8, 10) +
                  "-" +
                  modalData.riscattatoData.slice(5, 8) +
                  "-" +
                  modalData.riscattatoData.slice(0, 5)
                }}</span>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-margint-top">
          <ion-col size="auto">
            <ion-button @click="showModal = false"> Close </ion-button>
          </ion-col>
        </ion-row>
      </div>
    </div> -->
  </campaign-layout>
</template>

<script>
import "swiper/swiper-bundle.min.css"
import "swiper/components/pagination/pagination.scss"
import "@ionic/vue/css/ionic-swiper.css"
// import "swiper/css";
// import "swiper/css/effect-coverflow";

import {
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonContent,
  IonPage,
  toastController,
  IonButton,
  IonicSwiper,
  modalController,
} from "@ionic/vue"
import { lockOpen, lockClosed, checkmarkCircle } from "ionicons/icons"

import { ref } from "vue"

import ApiService from "../../common/service.api"

import { translate } from "../../common/helper.auth"

import CampaignLayout from "../../components/CampaignLayout"
import ModalInfoAward from "../../views/Modals/ModalInfoAward.vue"

import { useRoute } from "vue-router"

import SwiperCore, { Navigation, EffectCoverflow, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/vue"
import CommonCampaignMenu from "../../components/CampaignMenu"

SwiperCore.use([IonicSwiper, Navigation, EffectCoverflow, Pagination])

export default {
  name: "Award",
  components: {
    IonIcon,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonContent,
    IonPage,
    toastController,
    CampaignLayout,
    IonButton,
    Swiper,
    SwiperSlide,
    CommonCampaignMenu,
    ModalInfoAward,
  },
  created() {
    console.log("GET COOKIE DEBUG, ", this.$cookies.get("debug"))
  },
  setup() {
    const route = useRoute()
    const { id } = route.params

    const awardsList = ref([])

    function apiGet() {
      ApiService.get(`CampaignManager/GetUserAward/${id}`).then((res) => {
        console.log(res)
        res.data.forEach((element) => {
          if ("title" in element) {
            element.title = translate(element.title)
          }

          awardsList.value.push(element)
        })
      })
    }

    apiGet()

    return {
      id,
      awardsList,
      apiGet,
      EffectCoverflow,
    }
  },
  data() {
    return {
      lockOpen,
      lockClosed,
      checkmarkCircle,

      showModal: false,
      modalData: {},
    }
  },
  methods: {
    redeemAward(award) {
      console.log("MI HAI CLICCATO!", award)
      ApiService.post(`CampaignManager/RedeemAdword/${award.id}`).then(
        async (res) => {
          console.log(res)

          // const toast = await toastController.create({
          //   header: "AWARD CLAIMED SUCCESFULLY",
          //   message: `Award: "${award.title}" was claimed succesfully`,
          //   position: "middle",
          //   duration: 2800,
          // });
          // await toast.present();
        }
      )
    },
    // async openModal(slide) {
    //   console.log(" emmanuele SLIDE DELLAWARD ", slide);
    //   const modal = await modalController.create({
    //     component: ModalInfoAward,
    //     cssClass: "common-modal",
    //     componentProps: {
    //       award: slide,
    //     },
    //   });
    //   return modal.present();
    // },
    presentAlert(item) {
      this.modalData = {}

      this.showModal = true

      this.modalData = JSON.parse(JSON.stringify(item))

      console.log(this.modalData)
    },
  },
  computed: {
    computed_debug() {
      if (this.$cookies.get("debug") == 1) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped lang="scss">
/* * {
  border: 1px solid rgb(168, 255, 168);
} */

.slide {
  width: 200px;
  height: 300px;
  margin: 30px 0;
  padding: 1rem 0.2rem;
  border-radius: 10px;
}

.slide-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
  //   * {
  //     border: 1px solid salmon;
  //   }
}

.slide-title {
  font-size: 0.5rem;
}

.slide-img {
  display: grid;
  place-content: center;
  width: auto;
  max-height: 50%;

  ion-avatar {
    width: 130px;
    height: 130px;
  }
}

.slide-img img {
  height: 100%;
}

.slide-btn {
  align-self: center;
  width: fit-content;
}

// ion-button::part(native) {
//   background-color: var(--text-color-light);
//   color: var(--ion-color-primary);
//   border-radius: var(--border-radius);
// }

ion-icon {
  --ionicon-stroke-width: 32px;
}

.icon-premio-riscattato {
  font-size: 4rem;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
  z-index: 10;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 1rem 1.5rem;
  width: 80vw;
  border-radius: 0.5rem;
}

.show-modal {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.image {
  padding: 0.5rem;
  display: grid;
  place-content: center;

  ion-avatar {
    width: 40vw;
    height: 40vw;
  }
}

.progressbar-container {
  padding: 3rem 0;
  width: 30vw;
  margin: auto;
  ion-progress-bar {
    border-radius: 20px;
  }
}

.description-container {
  margin-bottom: 3rem;
}

.title-container {
  margin-bottom: 1rem;
}
</style>
